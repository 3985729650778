<template>
  <span
    v-show="displayWarning"
    v-tooltip="'In order to see the Adjusted Revenue and Estimated Advertising Cost of Revenue values, you first need to assign Metrics to your campaigns.'"
    class="pi pi-exclamation-triangle text-orange-500 mr-3"
  />
  <router-link
    :to="{ name: 'adGroups', params: { id: campaignId }, query }"
  >
    {{ campaignName }}
  </router-link>
</template>

<script>

export default {
  inject: ['query'],
  props: {
    profileMetricsSetted: {
      type: Boolean,
      required: true,
    },
    sales: {
      type: Number,
      required: true,
    },
    kenpRoyalties: {
      type: Number,
      required: true,
    },
    campaignState: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    campaignName: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayWarning() {
      // Don't show warning if metrics are setted
      if (this.profileMetricsSetted) {
        return false;
      }

      // If metrics are setted and campaign is enabled, show warning
      if (this.campaignState === 'ENABLED') {
        return true;
      }

      // For campaigns that are not enabled, only show warning if kenp and sales are non 0
      if (this.kenpRoyalties > 0 && this.sales > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
